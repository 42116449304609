
import React, { useEffect, useState } from 'react';
import { IoLanguageSharp } from "react-icons/io5";

function GoogleTranslate() {
  const [selectedLanguage, setSelectedLanguage] = useState(''); // State to track the selected language

  // Initialize Google Translate script and setup
  useEffect(() => {
    if (!document.querySelector("[src='https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit']")) {
      // Define the Google Translate initialization function
      window.googleTranslateElementInit = () => {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            includedLanguages: 'en,hi,gu', // Languages to be supported
            autoDisplay: false, // Disable automatic display of Google's dropdown
          },
          'google_translate_element'
        );
      };

      // Append the Google Translate script dynamically
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.body.appendChild(script);
    }
  }, []);

  // Function to handle manual language translation
  const handleLanguageChange = (e) => {
    const languageCode = e.target.value;
    setSelectedLanguage(languageCode);

    // Manually trigger the language change using Google Translate
    const selectField = document.querySelector('.goog-te-combo');
    if (selectField) {
      selectField.value = languageCode;
      selectField.dispatchEvent(new Event('change')); // Trigger the change event on Google Translate
    }
  };

  return (
<div style={{ display: "flex",  alignItems:"center"}}>
{/* Custom dropdown for language selection */}
      <IoLanguageSharp size={25} />
      <select 
  onChange={handleLanguageChange}
  value={selectedLanguage}
  style={{
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    appearance: 'none',  // Removes the default arrow
    WebkitAppearance: 'none',  // Safari and Chrome support
    MozAppearance: 'none',  // Firefox support
    cursor: 'pointer', // Optional: makes it look clickable
    boxShadow: 'none', // Removes any box-shadow on focus
    WebkitBoxShadow: 'none', // For Safari/Chrome
  }}  
  className="custom-language-dropdown nav-link dropdown-toggle d-flex align-items-center gap-1"
>
  <option value="">Select Language</option>
  <option value="en">English</option>
  <option value="hi">Hindi</option>
  <option value="gu">Gujarati</option>
</select>


      {/* Hidden element for Google Translate */}
      <div id="google_translate_element" style={{ display: 'none' }}></div>
    </div>
  );
}

export default GoogleTranslate;


// import React, { useEffect } from 'react';
// import { IoLanguageSharp } from "react-icons/io5";

// function GoogleTranslate() {
//   // Use a flag to ensure the script is only added once
//   useEffect(() => {
//     // Check if the script is already added
//     if (!document.querySelector("[src='https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit']")) {
//       // Define the Google Translate initialization function
//       window.googleTranslateElementInit = () => {
//         if (window.google && window.google.translate) {
//           new window.google.translate.TranslateElement(
//             {
//               pageLanguage: 'en',
//               includedLanguages: 'en,hi,gu', // Include the languages you want
//               layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
//             },
//             'google_translate_element'
//           );
//         }
//       };

//       // Create and append the Google Translate script dynamically if it doesn't exist
//       const script = document.createElement('script');
//       script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
//       script.async = true; // Load the script asynchronously
//       document.body.appendChild(script);
//     }

//     // Cleanup is not required since the script only loads once
//   }, []);

  

//   return (
//     <>
//      <IoLanguageSharp size={25} /> <span  id="google_translate_element" >Languages</span> 

//     </>
   
    
     
//     )
//   ;
// }

// export default GoogleTranslate;

