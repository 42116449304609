import React from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function Chat() {
  return (
    <TawkMessengerReact
      propertyId="66fe390e37379df10df11a3d"
      widgetId="1i98gtohi" />
  );
}

export default Chat;
