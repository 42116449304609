import { Button } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
// import barcodeImg from 'assets/images/barcode.gif';

const CancelTicket = () => {
    const [ticketsData, setTicketsData] = useState([])
    const [userID, setUserID] = useState()

    useEffect(() => {
        const storedUserID = localStorage.getItem('UserID');
        if (storedUserID) {
            setUserID(storedUserID);
        }
    }, []);

    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        if (userID) {
            TicketsList();
        }
    }, [userID]);

    const TicketsList = async () => {
        let data = new FormData();
        data.append('user_id', localStorage.getItem('UserID'))
        data.append('type', 3)
        const params = {
            'user_id': localStorage.getItem('UserID'),
            'type': 3,
            // id: ticketId,
        }
        try {
            await axios.post("ticket_list", data, {
                params: params
            }).then((res) => {
                //console.log(res.data.data);
                setTicketsData(res.data.data)

            })
        }
        catch (error) {
            // toast.error(error.data.message);
        }
    }
    const handleTicketClick = (ticketId,type) => {
        window.location.href = `/ticket-details-view/${type}/${ticketId}?action=cancel`;
    };

    return (

        <div className="prevbookdiv">
            <div className="eidtproftitle titlediv my-3">
                <h5 className="text-capitalize fw-semibold fs-4">Cancel Ticket</h5>
            </div>

            {
                ticketsData?.ticket_list?.map((ticket, index) => (
                    // <div onClick={() => history.push(`/ticket-details-view/${ticket.id}`)}>
                    <div onClick={() => handleTicketClick(ticket.id,3)}>
                        <div className="ticket-container mt-4">
                            <div className="d-flex rounded-9 flex-column gap-4 buslist--card card shadow-hover border-hover-none p-3 "  >
                                <div className="d-flex justify-content-between align-items-center busnmflex ">
                                    <div className="busname--icons">
                                        <div className="d-flex align-items-center gap-2">
                                            <img src={ticketsData?.image_url + ticket?.bus_image} alt="" className="img-fluid" style={{ height: '60px', width: '60px', borderRadius: '100%', border: '1px solid black' }} />
                                            <div>
                                                <h5 className="m-0 me-2 fw-semibold">{ticket.bus_name}</h5>
                                                <p className="m-0 me-2">{ticket?.bus_ac} </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h4 className="fw-medium m-0 tcktpricediv" style={{ color: 'rgb(108 42 127)' }} >₹{ticket?.final_price}</h4>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-12 align-items-center">
                                        <div >
                                            <div className="d-flex justify-content-between align-items-center bustimeflex">
                                                <div className="bustimediv">
                                                    <h6 className="fw-semibold mb-1">{ticket?.starting_point_name}</h6>
                                                    <p className="fw-medium m-0" style={{ color: 'rgb(108 42 127)', fontSize: '17px' }}>3.00 pm</p>
                                                    <p className="fw-medium m-0">{ticket.bording_time}</p>
                                                </div>
                                                <div className="bustimediv flxbasissdiv">
                                                    <span className="border--span"></span>
                                                    <p className="bustotalhours fw-medium m-0">{ticket?.time_different}</p>
                                                </div>
                                                <div className="bustimediv">
                                                    <h6 className="fw-semibold mb-1" style={{display:"flex",justifyContent:"end"}}>{ticket?.ending_point_name}</h6>
                                                    <p className="fw-medium m-0" style={{ color: 'rgb(108 42 127)', fontSize: '17px' }}>9.00 pm </p>
                                                    <p className="fw-medium m-0">{ticket.droping_time}</p>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))
            }

            {/* <div className="cancel-ticket">
                <div className="align-items-center d-flex header justify-content-between px-4 py-2">
                    <h4 className="fs-5 fw-semibold mb-0">Raghav Travels</h4>
                    <Button className="bookagainbtn px-5  fw-semibold btn text-capitalize">Book Again</Button>
                </div>
                <div className="px-4 py-2 rounded-bottom-3 " style={{border:'1px solid red', borderStyle:'dashed'}}>
                    <div className="numberpass--seatdet d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="fw-medium py-1 fs-18 text-capitalize border-right-2 pe-3 text-body-tertiary">Passenger 1</div>
                            <div className="seat-numdiv d-flex gap-3 text-capitalize ps-3">
                                <strong>Seat 17fdC</strong>
                                <strong>Seat 17C</strong>
                                <strong>Seat 17C</strong>
                            </div>
                        </div>
                        <div className="bookdatdiv">
                            <div className="text-end">
                                <strong>Date:</strong> 14/6/2024
                            </div>
                        </div>
                    </div>
                    <div className="tctshr--rowdiv mt-2">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="tct--col py-3 border-right-dashed-1">
                                    <div className="frm-group">
                                        <h6 className="fw-semibold">Name</h6>
                                        <p className="fw-medium text-body-tertiary">Chandler M Bing</p>
                                    </div>
                                    <div className="frm-group mb-2">
                                        <h6 className="fw-semibold">Mobile Number</h6>
                                        <p className="fw-medium text-body-tertiary">+91 9988776655</p>
                                    </div>
                                    <div className="frm-group mb-2">
                                        <h6 className="fw-semibold">Gender</h6>
                                        <p className="fw-medium text-body-tertiary">Male</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8">
                                <div className="tct--col">
                                    <div className="row">
                                        <div className="col-md-6 frm-group mb-2">
                                            <h6 className="fw-semibold">Boarding Point</h6>
                                            <p className="fw-medium text-body-tertiary">Prime Arcade Kalupur Bank Glorious Hotel</p>
                                        </div>
                                        <div className="col-md-6 frm-group mb-2">
                                            <h6 className="fw-semibold">Dropping Point</h6>
                                            <p className="fw-medium text-body-tertiary">Jetpur</p>
                                        </div>
                                    </div>
                                    <div className="frm-group mb-2">
                                        <h6 className="fw-semibold">Bus Number</h6>
                                        <p className="fw-medium text-body-tertiary">GJ5 2299</p>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 frm-group mb-2">
                                            <h6 className="fw-semibold">Contact Travel Agent</h6>
                                            <p className="fw-medium text-body-tertiary">+91 95856685412</p>
                                        </div>
                                        <div className="col-md-6 frm-group mb-2">
                                            <h6 className="fw-semibold">Mail Us</h6>
                                            <p className="fw-medium text-body-tertiary">sarthi@gmail.com</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 frm-group">
                                            <h6 className="fw-semibold">Fare</h6>
                                            <p className="fw-medium fw-bold mb-0 text-black fs-5">₹700 Paid</p>
                                        </div>
                                        <div className="col-md-6 frm-group">
                                            <div className="barcodeimg text-end">
                                                            <img src={barcodeImg} alt="Barcode" width="200px" className="img-fluid" />
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default CancelTicket;
