import axios from "axios";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { FaFacebook } from "react-icons/fa6";
import { FaSquareInstagram } from "react-icons/fa6";
import Header from "../header";
import { TextField } from "@mui/material";
import { FaCopy } from "react-icons/fa";
import referAndEarn from "./referAndEarn.json";
import Lottie from "lottie-react";

const Referral = () => {

    const [referralCode, setReferralCode] = useState("");
    const [referralData, setReferralData] = useState({});

    useEffect(() => {
        window.scrollTo(0, 0);
        getReferralData()

    }, []);

    const getReferralData = async () => {
        let data = new FormData();
        data.append('user_id', localStorage.getItem('UserID'))

        try {
            await axios.post("refer_data", data, {

            }).then((res) => {
                setReferralData(res.data.data)
                setReferralCode(res.data.data.referral_code?res.data.data.referral_code:"Not Found")
                // console.log(referralCode, "referralData")
            })
        } catch (error) {
            toast.error(error.response?.data?.message || 'API is not working'); // Safely accessing the error message
        }
    };

    const handleCopy = () => {

        navigator.clipboard.writeText(referralCode)
        // toast.success('Copied TO Clipboard')
        setReferralCode("Text Copied !")

        setTimeout(() => {
            getReferralData();
        }, 500);
        
    };

    return (
        <>
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <div>
                    <div className="container my-5 ">
                        <div className="card shadow">
                            <div className="card-body">
                                <div className="d-flex gap-2 flex-wrap flex-md-nowrap justify-content-between align-items-center" >
                                    {/* <img className="w-25 rounded mx-5 align-center" src="/assets/images/Referral.jpg" alt="Referral" /> */}
                                    <Lottie className="w-30 "  animationData={referAndEarn} loop={true} />
                                    <div  >
                                        <h3  className="mb-5">{referralData.title}

                                        </h3>
                                        <ul style={{ listStyle:'none',  margin: '0px', padding: '0px',  }} >
                                            
                                            {referralData?.points?.map((point) => (
                                                <li key={point.id}>  <img style={{ cursor: 'pointer', width: '20px', marginBlock: '5px',  paddingBottom: '4px', }} src="/assets/icons/front-bus.png" alt="copy icon"/> {point.title}</li>
                                            ))}
                                        </ul>
                                        <p  className="my-3 fs-6" >
                                            <span className="border px-2 py-2 text-bolder   fs-5" >
                                                {referralCode}
                                            </span>
                                            <img style={{ cursor: 'pointer', width: '30px', margin: '10px', paddingBottom: '4px', color: "#6c2a7f" }} src="/assets/icons/copying.png" alt="copy icon" onClick={handleCopy} />
                                            {/* <FaCopy onClick={handleCopy}
                                                className="mx-2  mb-2 fs-3"
                                                /> */}
                                        </p>
                                        <span style={{ backgroundColor: "#44164c" }} className="text-bold rounded text-white fs-6 py-1 pb-2 px-3">Referral A Friend!</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Referral