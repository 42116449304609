


import React, { useEffect, useState } from 'react';
import { Route, useHistory, useLocation, Switch } from 'react-router-dom';
import EditProfile from '../components/profile/editProfile';
import PreviousBooking from '../components/profile/previosbooking';
import CancelTicket from '../components/profile/cancel-ticket';
import ShowMyTicket from '../components/profile/show-my-ticket';
import TermsConditions from '../components/profile/terms-and-conditions';
import CancelPolicy from '../components/profile/cancel-policy';
import PrivacyPolicy from '../components/profile/privacy-policy';
import Faq from '../components/profile/faq';
import Supports from '../components/profile/supports';
import ShippingPolicy from '../components/profile/shipping-policy';
import Header from '../components/header';
import Footer from '../components/footer';
import Referral from '../components/profile/referral';
import Wallet from '../components/profile/wallet';

const ProfileDetails = () => {
    const history = useHistory();
    const location = useLocation();

    const [activeSection, setActiveSection] = useState('');

    useEffect(() => {
        if (location.pathname) {
            const section = location.pathname.split('/').pop();
            setActiveSection(section);
        }
    }, [location.pathname]);

    return (
        <>
            <Header />
            <div className="profile--main mt-5">
                <div className="profile--maindiv">
                    <div className="container">
                        <div className="profilepage--div">
                            <div className="profilediv--row">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="profile--col position-sticky top-15px" style={{ cursor: "pointer" }}>
                                            <div className="features--div">
                                                <ul className="nav featurenav nav-tabs text-capitalize flex-column justify-content-end border-0">
                                                    {localStorage.getItem('UserID') &&
                                                        <li className="nav-item border-bottom-1">
                                                            <a href='/profile/edit-profile' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'edit-profile' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">My Profile</span>
                                                            </a>
                                                        </li>}
                                                    {localStorage.getItem('UserID') &&
                                                        <li className="nav-item border-bottom-1">
                                                            <a href='/profile/wallet' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'edit-profile' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Wallet</span>
                                                            </a>
                                                        </li>}
                                                    <li className="nav-item border-bottom-1">
                                                        <a href='/profile/support' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'support' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                            <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Support</span>
                                                        </a>
                                                    </li>
                                                    {localStorage.getItem('UserID') &&
                                                        <li className="nav-item border-bottom-1">
                                                            <a href='/profile/referral' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'referral' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">referral</span>
                                                            </a>
                                                        </li>
                                                    }

                                                    {localStorage.getItem('UserID') &&
                                                        <li className="nav-item border-bottom-1">
                                                            <a href='/profile/previous-booking' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'previous-booking' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Previous Booking</span>
                                                            </a>
                                                        </li>}
                                                    {localStorage.getItem('UserID') &&
                                                        <li className="nav-item border-bottom-1">
                                                            {/* <NavLink to="/profile/cancel-ticket" activeClassName="active" style={{ textDecoration: 'none' }}> */}
                                                            <a href='/profile/cancel-ticket' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'cancel-ticket' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Cancel Ticket</span>
                                                            </a>
                                                            {/* <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Cancel Ticket</span>
                                                            </div> */}
                                                            {/* </NavLink> */}
                                                        </li>}
                                                    {localStorage.getItem('UserID') &&
                                                        <li className="nav-item border-bottom-1">
                                                            {/* <NavLink to="/profile/show-my-ticket" activeClassName="active" style={{ textDecoration: 'none' }}> */}
                                                            {/* <div className="smooth my-2 btn-theme-opacity"> */}
                                                            <a href='/profile/show-my-ticket' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'show-my-ticket' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Show My Ticket</span>
                                                            </a>
                                                            {/* </div> */}
                                                            {/* </NavLink> */}
                                                        </li>}
                                                    <li className="nav-item border-bottom-1">
                                                        <a href="/profile/shipping-delivery" className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'shipping-delivery' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Shipping and Delivery</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item border-bottom-1">
                                                        <a href="/profile/terms-conditions" className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'terms-conditions' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Terms & Conditions</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item border-bottom-1">
                                                        <a href="/profile/cancel-refund-policy" className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'cancel-refund-policy' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Cancel & Refund Policy</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item border-bottom-1">
                                                        <a href="/profile/privacy-policy" className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'privacy-policy' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Privacy Policy</span>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="nav-item border-bottom-1">
                                                        <a href="/profile/faq" className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'faq' ? 'active' : ''}`} style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">FAQ</span>
                                                            </div>
                                                        </a>
                                                    </li>

                                                    {/* Add more links similarly */}
                                                </ul>

                                                <ul className="nav featurenav nav-tabs text-capitalize flex-column justify-content-end border-0">
                                                    {/* {localStorage.getItem('UserID') &&
                                                    <li className="nav-item border-bottom-1">
                                                        <a href='/profile/edit-profile' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'edit-profile' ? 'active' : ''}`}>
                                                            <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Edit Profile</span>
                                                        </a>
                                                    </li>
                                                } */}
                                                    {/* <li className="nav-item border-bottom-1">
                                                    <a href='/profile/support' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'support' ? 'active' : ''}`}>
                                                        <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Support</span>
                                                    </a>
                                                </li> */}
                                                    {/* {localStorage.getItem('UserID') &&
                                                    <li className="nav-item border-bottom-1">
                                                        <NavLink to="/profile/previous-booking" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Previous Booking</span>
                                                            </div>
                                                        </NavLink>
                                                    </li>} */}
                                                    {/* {localStorage.getItem('UserID') &&
                                                    <li className="nav-item border-bottom-1">
                                                        <NavLink to="/profile/cancel-ticket" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Cancel Ticket</span>
                                                            </div>
                                                        </NavLink>
                                                    </li>}
                                                {localStorage.getItem('UserID') &&
                                                    <li className="nav-item border-bottom-1">
                                                        <NavLink to="/profile/show-my-ticket" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                            <div className="smooth my-2 btn-theme-opacity">
                                                                <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Show My Ticket</span>
                                                            </div>
                                                        </NavLink>
                                                    </li>} */}
                                                    {/* <li className="nav-item border-bottom-1">
                                                    <NavLink to="/profile/shipping-delivery" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                        <div className="smooth my-2 btn-theme-opacity">
                                                            <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Shipping and Delivery</span>
                                                        </div>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item border-bottom-1">
                                                    <NavLink to="/profile/terms-conditions" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                        <div className="smooth my-2 btn-theme-opacity">
                                                            <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Terms & Conditions</span>
                                                        </div>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item border-bottom-1">
                                                    <NavLink to="/profile/cancel-refund-policy" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                        <div className="smooth my-2 btn-theme-opacity">
                                                            <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Cancel & Refund Policy</span>
                                                        </div>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item border-bottom-1">
                                                    <NavLink to="/profile/privacy-policy" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                        <div className="smooth my-2 btn-theme-opacity">
                                                            <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Privacy Policy</span>
                                                        </div>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item border-bottom-1">
                                                    <NavLink to="/profile/faq" activeClassName="active" style={{ textDecoration: 'none' }}>
                                                        <div className="smooth my-2 btn-theme-opacity">
                                                            <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">FAQ</span>
                                                        </div>
                                                    </NavLink>
                                                </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-9">
                                        <Switch>
                                            <Route path="/profile/edit-profile" component={EditProfile} />
                                            <Route path="/profile/wallet" component={Wallet} />
                                            <Route path="/profile/referral" component={Referral} />
                                            <Route path="/profile/support" component={Supports} />
                                            <Route path="/profile/previous-booking" component={PreviousBooking} />
                                            <Route path="/profile/cancel-ticket" component={CancelTicket} />
                                            <Route path="/profile/show-my-ticket" component={ShowMyTicket} />
                                            <Route path="/profile/shipping-delivery" component={ShippingPolicy} />
                                            <Route path="/profile/terms-conditions" component={TermsConditions} />
                                            <Route path="/profile/cancel-refund-policy" component={CancelPolicy} />
                                            <Route path="/profile/privacy-policy" component={PrivacyPolicy} />
                                            <Route path="/profile/faq" component={Faq} />
                                            {/* Add more routes similarly */}
                                        </Switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ProfileDetails;



// import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
// // Import your components...
// import Header from '../components/header';
// import Footer from '../components/footer';
// import EditProfile from '../components/profile/editProfile';
// import { useEffect, useState } from 'react';
// import PreviousBooking from '../components/profile/previosbooking';
// import CancelTicket from '../components/profile/cancel-ticket';
// import ShowMyTicket from '../components/profile/show-my-ticket';
// import TermsConditions from '../components/profile/terms-and-conditions';
// import CancelPolicy from '../components/profile/cancel-policy';
// import PrivacyPolicy from '../components/profile/privacy-policy';
// import Faq from '../components/profile/faq';
// import Supports from '../components/profile/supports';
// import { FaPowerOff } from 'react-icons/fa6';
// import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import { toast, ToastContainer } from 'react-toastify';
// import ShippingPolicy from '../components/profile/shipping-policy';

// // Import other components...

// const ProfileDetails = () => {
//     const [openEdit, setopenEdit] = useState(true)
//     const [openSupport, setOpenSupport] = useState(!localStorage.getItem('UserID') ? true : false)
//     const [openLastBooking, setOpenLastBooking] = useState(false)
//     const [openMyTicket, setOpenMyTicket] = useState(false)
//     const [openCancelTicket, setCancelTicket] = useState(false)
//     const [openTermsConditions, setOpenTermsConditions] = useState(false)
//     const [openCancelPolicy, setOpenCancelPolicy] = useState(false)
//     const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)
//     const [openFaq, setOpenFaq] = useState(false)
//     const [openShipplngPoilcy, setOpenShipplngPoilcy] = useState(false)
//     const [open, setOpen] = useState(false);
//     const history = useHistory();
//     const [activeSection, setActiveSection] = useState('edit-profile');
//     const location = useLocation();

//     useEffect(() => {
//         const handleHashChange = () => {
//             if (location.hash) {
//                 const sectionId = location.hash.replace("#", "");
//                 const element = document.getElementById(sectionId);
//                 if (element) {
//                     element.scrollIntoView({ behavior: "smooth" });
//                 }
//                 setActiveSection(sectionId);
//             }
//         };

//         handleHashChange(); // Run on component mount

//         // Listen for hash changes
//         window.addEventListener("hashchange", handleHashChange);

//         return () => {
//             window.removeEventListener("hashchange", handleHashChange);
//         };
//     }, [location.hash]);



//     useEffect(() => {
//         window.scrollTo(0, 0);
//     }, []);

//     const handleEditProfile = () => {
//         setopenEdit(true)
//         setOpenLastBooking(false)
//         setCancelTicket(false)
//         setOpenSupport(false)
//         setOpenCancelPolicy(false)
//         setOpenMyTicket(false)
//         setOpenFaq(false)
//         setOpenPrivacyPolicy(false)
//         setOpenTermsConditions(false)
//         setOpenShipplngPoilcy(false);
//     }
//     const handlelastBooking = () => {
//         setOpenLastBooking(true)
//         setopenEdit(false)
//         setCancelTicket(false)
//         setOpenMyTicket(false)
//         setOpenSupport(false)
//         setOpenShipplngPoilcy(false);
//     }

//     const handleCancelTicket = () => {
//         setCancelTicket(true)
//         setopenEdit(false)
//         setOpenLastBooking(false)
//         setOpenMyTicket(false)
//         setOpenSupport(false)
//         setOpenShipplngPoilcy(false);
//     }

//     const handleMyTicket = () => {
//         setOpenMyTicket(true)
//         setCancelTicket(false)
//         setopenEdit(false)
//         setOpenLastBooking(false)
//         setOpenSupport(false)
//         setOpenFaq(false)
//         setOpenTermsConditions(false)
//         setOpenCancelPolicy(false)
//         setOpenPrivacyPolicy(false)
//         setOpenShipplngPoilcy(false);
//     }
//     const handleTermsConditions = () => {
//         setOpenTermsConditions(true)
//         setCancelTicket(false)
//         setopenEdit(false)
//         setOpenLastBooking(false)
//         setOpenCancelPolicy(false)
//         setOpenFaq(false)
//         setOpenPrivacyPolicy(false)
//         setOpenMyTicket(false)
//         setOpenSupport(false)
//         setOpenShipplngPoilcy(false);
//     }
//     const handleCancelPolicy = () => {
//         setOpenCancelPolicy(true)
//         setCancelTicket(false)
//         setopenEdit(false)
//         setOpenLastBooking(false)
//         setOpenFaq(false)
//         setOpenPrivacyPolicy(false)
//         setOpenMyTicket(false)
//         setOpenSupport(false)
//         setOpenTermsConditions(false)
//         setOpenShipplngPoilcy(false);
//     }
//     const handlePrivacyPolicy = () => {
//         setOpenPrivacyPolicy(true)
//         setCancelTicket(false)
//         setopenEdit(false)
//         setOpenLastBooking(false)
//         setOpenFaq(false)
//         setOpenMyTicket(false)
//         setOpenSupport(false)
//         setOpenTermsConditions(false)
//         setOpenCancelPolicy(false)
//         setOpenShipplngPoilcy(false);
//     }
//     const handleFaqs = () => {
//         setOpenFaq(true)
//         setCancelTicket(false)
//         setopenEdit(false)
//         setOpenLastBooking(false)
//         setOpenMyTicket(false)
//         setOpenSupport(false)
//         setOpenTermsConditions(false)
//         setOpenCancelPolicy(false)
//         setOpenPrivacyPolicy(false)
//         setOpenShipplngPoilcy(false);
//         setOpenPrivacyPolicy(false)
//     }
//     const handleSupports = () => {
//         setOpenSupport(true)
//         setCancelTicket(false)
//         setopenEdit(false)
//         setOpenLastBooking(false)
//         setOpenMyTicket(false)
//         setOpenTermsConditions(false)
//         setOpenCancelPolicy(false)
//         setOpenPrivacyPolicy(false)
//         setOpenFaq(false)
//         setOpenShipplngPoilcy(false);

//     }

//     const handleShippingPolicy = () => {
//         setopenEdit(false);
//         setOpenSupport(false);
//         setCancelTicket(false);
//         setOpenLastBooking(false)
//         setOpenMyTicket(false)
//         setOpenTermsConditions(false)
//         setOpenCancelPolicy(false)
//         setOpenPrivacyPolicy(false)
//         setOpenFaq(false);
//         setOpenShipplngPoilcy(true)
//     }
//     // const handleLogOut = () => {
//     //     setOpenLogOut(true)
//     // }


//     const handleLogOut = async () => {
//         localStorage.removeItem('UserName')
//         localStorage.removeItem('EmailID')
//         localStorage.removeItem('MobileNo')
//         localStorage.removeItem('UserID')
//         toast.success('Logut Successfully');
//         setTimeout(() => {

//             history.push('/')
//         }, 2000);
//     }

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };




//     return (
//         <Router>
//             <Header />
//             <ToastContainer
//                 position="top-right"
//                 autoClose={5000}
//                 hideProgressBar={false}
//                 newestOnTop={false}
//                 closeOnClick
//                 rtl={false}
//                 pauseOnFocusLoss
//                 draggable
//                 pauseOnHover
//             />
//             <main>
//                 <Dialog
//                     open={open}
//                     // TransitionComponent={Transition}
//                     sx={{
//                         "& .MuiDialog-container": {
//                             "& .MuiPaper-root": {
//                                 width: "30%",
//                                 maxWidth: "375px",  // Set your width here
//                             },
//                         },
//                     }}
//                     keepMounted
//                     onClose={handleClose}
//                     aria-describedby="alert-dialog-slide-description"
//                 >
//                     <DialogTitle style={{ color: "red" }} className='text-center'>  {"Logout"}</DialogTitle>
//                     <DialogContent>
//                         <DialogContentText id="alert-dialog-slide-description" className='text-center'>
//                             Are you sure you want to Log Out ?
//                         </DialogContentText>
//                     </DialogContent>
//                     <DialogActions className='text-center justify-content-center'>
//                         <Button color="error" onClick={() => setOpen(false)}>Cancel</Button>
//                         <Button variant="contained" style={{ color: 'white', backgroundColor: 'red' }} onClick={handleLogOut}>Yes Logout!</Button>
//                     </DialogActions>
//                 </Dialog>


//                 <div className="profile--main mt-5">
//                     <div className="profile--maindiv">
//                         <div className="container">
//                             <div className="profilepage--div">
//                                 <div className="profilediv--row">
//                                     <div className="row">
//                                         <div className="col-md-3">
//                                             <div className="profile--col position-sticky top-15px" style={{ cursor: "pointer" }}>
//                                                 <div className="features--div">
//                                                     <ul className="nav featurenav nav-tabs text-capitalize flex-column justify-content-end border-0">
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1" onClick={handleEditProfile}>
//                                                                 <a style={{ textDecoration: 'none' }} href='#edit-profile'>
//                                                                     <div className="smooth mb-2 mt-0 btn-theme-opacity">
//                                                                         <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" activeClassName="active">Edit Profile</span>
//                                                                     </div>
//                                                                 </a>
//                                                             </li>}
//                                                         <li className="nav-item border-bottom-1" onClick={handleSupports}>
//                                                             <a style={{ textDecoration: 'none' }} href='#support'>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Support</span>
//                                                                 </div>
//                                                             </a>
//                                                         </li>
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1" onClick={handlelastBooking}>
//                                                                 <a style={{ textDecoration: 'none' }} href='#previous-booking'>
//                                                                     <div className="smooth my-2 btn-theme-opacity">
//                                                                         <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Previous Booking</span>
//                                                                     </div>
//                                                                 </a>
//                                                             </li>}
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1" onClick={handleCancelTicket}>
//                                                                 <a style={{ textDecoration: 'none' }} href='#cancel-ticket'>
//                                                                     <div className="smooth my-2 btn-theme-opacity">
//                                                                         <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Cancel Ticket </span>
//                                                                     </div>
//                                                                 </a>
//                                                             </li>}
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1" onClick={handleMyTicket}>
//                                                                 <a style={{ textDecoration: 'none' }} href='#show-my-ticket'>
//                                                                     <div className="smooth my-2 btn-theme-opacity">
//                                                                         <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Show My Ticket</span>
//                                                                     </div>
//                                                                 </a>
//                                                             </li>}
//                                                         <li className="nav-item border-bottom-1">
//                                                             <a style={{ textDecoration: 'none' }} href='#shipping-delivery'>
//                                                                 <div className="smooth my-2 btn-theme-opacity" onClick={handleShippingPolicy}>
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Shipping and Delivery</span>
//                                                                 </div>
//                                                             </a>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1" onClick={handleTermsConditions}>
//                                                             <a style={{ textDecoration: 'none' }} href='#terms-conditions'>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Terms & Conditions</span>
//                                                                 </div>
//                                                             </a>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1" onClick={handleCancelPolicy}>
//                                                             <a style={{ textDecoration: 'none' }} href='#cancel-refund-policy'>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Cancel & Refund Policy</span>
//                                                                 </div>
//                                                             </a>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1" onClick={handlePrivacyPolicy}>
//                                                             <a style={{ textDecoration: 'none' }} href='#privacy-policy'>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >Privacy Policy</span>
//                                                                 </div>
//                                                             </a>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1" onClick={handleFaqs}>
//                                                             <a style={{ textDecoration: 'none' }} href='#faq'>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style" >FAQ</span>
//                                                                 </div>
//                                                             </a>
//                                                         </li>
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1" onClick={handleClickOpen}>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style text-danger" >Log Out</span>
//                                                                 </div>
//                                                             </li>
//                                                         }
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>

//                                         <div className="col-md-9">
//                                             {localStorage.getItem('UserID') && openEdit && <div id="edit-profile"><EditProfile /></div>}
//                                             {openSupport && <div id="support"><Supports /></div>}
//                                             {openLastBooking && <div id="previous-booking"><PreviousBooking /></div>}
//                                             {openMyTicket && <div id="show-my-ticket"><ShowMyTicket /></div>}
//                                             {openCancelTicket && <div id="cancel-ticket"><CancelTicket /></div>}
//                                             {openTermsConditions && <div id="terms-conditions"><TermsConditions /></div>}
//                                             {openCancelPolicy && <div id="cancel-refund-policy"><CancelPolicy /></div>}
//                                             {openPrivacyPolicy && <div id="privacy-policy"><PrivacyPolicy /></div>}
//                                             {openFaq && <div id="faq"><Faq /></div>}
//                                             {openShipplngPoilcy && <div id="shipping-delivery"><ShippingPolicy /></div>}
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>


//             <Footer />
//         </Router>
//     );
// }

// export default ProfileDetails;


// import { BrowserRouter as Router, Route, Switch, NavLink } from 'react-router-dom';
// import { useEffect, useState } from 'react';
// import Header from '../components/header';
// import Footer from '../components/footer';
// import EditProfile from '../components/profile/editProfile';
// import PreviousBooking from '../components/profile/previosbooking';
// import CancelTicket from '../components/profile/cancel-ticket';
// import ShowMyTicket from '../components/profile/show-my-ticket';
// import TermsConditions from '../components/profile/terms-and-conditions';
// import CancelPolicy from '../components/profile/cancel-policy';
// import PrivacyPolicy from '../components/profile/privacy-policy';
// import Faq from '../components/profile/faq';
// import Supports from '../components/profile/supports';
// import ShippingPolicy from '../components/profile/shipping-policy';
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// const ProfileDetails = () => {
//     const [activeSection, setActiveSection] = useState('');
//     const history = useHistory();
//     const location = useLocation();

//     useEffect(() => {
//         if (location.pathname) {
//             const section = location.pathname.split('/').pop();
//             setActiveSection(section);
//         }
//     }, [location.pathname]);

//     return (
//         <Router>
//             <Header />
//             <main>
//                 <div className="profile--main mt-5">
//                     <div className="profile--maindiv">
//                         <div className="container">
//                             <div className="profilepage--div">
//                                 <div className="profilediv--row">
//                                     <div className="row">
//                                         <div className="col-md-3">
//                                             <div className="profile--col position-sticky top-15px" style={{ cursor: "pointer" }}>
//                                                 <div className="features--div">
//                                                     <ul className="nav featurenav nav-tabs text-capitalize flex-column justify-content-end border-0">
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1">
//                                                                 <a href='/profile/edit-profile' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'edit-profile' ? 'active' : ''}`}>
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Edit Profile</span>
//                                                                 </a>
//                                                             </li>
//                                                             // <li className="nav-item border-bottom-1">
//                                                             //     <NavLink to="/profile/edit-profile" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                             //         <div className="smooth mb-2 mt-0 btn-theme-opacity">
//                                                             //             <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Edit Profile</span>
//                                                             //         </div>
//                                                             //     </NavLink>
//                                                             // </li>
//                                                         }
//                                                         <li className="nav-item border-bottom-1">
//                                                             <a href='/profile/support' className={`smooth mb-2 mt-0 btn-theme-opacity ${activeSection === 'support' ? 'active' : ''}`}>
//                                                                 <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Support</span>
//                                                             </a>
//                                                             {/* <NavLink to="/profile/support" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Support</span>
//                                                                 </div>
//                                                             </NavLink> */}
//                                                         </li>
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1">
//                                                                 <NavLink to="/profile/previous-booking" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                     <div className="smooth my-2 btn-theme-opacity">
//                                                                         <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Previous Booking</span>
//                                                                     </div>
//                                                                 </NavLink>
//                                                             </li>}
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1">
//                                                                 <NavLink to="/profile/cancel-ticket" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                     <div className="smooth my-2 btn-theme-opacity">
//                                                                         <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Cancel Ticket</span>
//                                                                     </div>
//                                                                 </NavLink>
//                                                             </li>}
//                                                         {localStorage.getItem('UserID') &&
//                                                             <li className="nav-item border-bottom-1">
//                                                                 <NavLink to="/profile/show-my-ticket" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                     <div className="smooth my-2 btn-theme-opacity">
//                                                                         <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Show My Ticket</span>
//                                                                     </div>
//                                                                 </NavLink>
//                                                             </li>}
//                                                         <li className="nav-item border-bottom-1">
//                                                             <NavLink to="/profile/shipping-delivery" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Shipping and Delivery</span>
//                                                                 </div>
//                                                             </NavLink>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1">
//                                                             <NavLink to="/profile/terms-conditions" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Terms & Conditions</span>
//                                                                 </div>
//                                                             </NavLink>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1">
//                                                             <NavLink to="/profile/cancel-refund-policy" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Cancel & Refund Policy</span>
//                                                                 </div>
//                                                             </NavLink>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1">
//                                                             <NavLink to="/profile/privacy-policy" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">Privacy Policy</span>
//                                                                 </div>
//                                                             </NavLink>
//                                                         </li>
//                                                         <li className="nav-item border-bottom-1">
//                                                             <NavLink to="/profile/faq" activeClassName="active" style={{ textDecoration: 'none' }}>
//                                                                 <div className="smooth my-2 btn-theme-opacity">
//                                                                     <span className="fw-medium fs-18 d-block py-3 custom-link no-link-style">FAQ</span>
//                                                                 </div>
//                                                             </NavLink>
//                                                         </li>
//                                                     </ul>
//                                                 </div>
//                                             </div>
//                                         </div>



//                                         <div className="col-md-9">
//                                             <Switch>
//                                                 <Route path="/profile/edit-profile" component={EditProfile} />
//                                                 <Route path="/profile/support" component={Supports} />
//                                                 <Route path="/profile/previous-booking" component={PreviousBooking} />
//                                                 <Route path="/profile/cancel-ticket" component={CancelTicket} />
//                                                 <Route path="/profile/show-my-ticket" component={ShowMyTicket} />
//                                                 <Route path="/profile/shipping-delivery" component={ShippingPolicy} />
//                                                 <Route path="/profile/terms-conditions" component={TermsConditions} />
//                                                 <Route path="/profile/cancel-refund-policy" component={CancelPolicy} />
//                                                 <Route path="/profile/privacy-policy" component={PrivacyPolicy} />
//                                                 <Route path="/profile/faq" component={Faq} />
//                                             </Switch>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </main>
//             <Footer />
//         </Router>
//     );
// };

// export default ProfileDetails;

