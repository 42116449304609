import { Button, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NavLink } from 'react-router-dom';


const Footer = () => {
  const logo = process.env.PUBLIC_URL + '../assets/images/sarthi bus logo.png';
  const history = useHistory();
  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  // const handleNavigation = (section) => {
  //   history.push(`/profile#${section}`);
  // };

  return (
    <footer>
      <div className="footer--div mt-100 pt-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="footer-col">
                <div className="footerlogo">
                  <img src={logo} alt="" className="img-fluid" onClick={() => history.push('/')} style={{ maxHeight: '60px', cursor: "pointer" }} />
                </div>
                <div className="footer-f-content mt-3">
                  <p className="fw-medium">
                    Sarthi Bus is your trusted partner for online bus ticket bookings, serving millions of satisfied travelers across the globe.
                    Sarthi Bus offers seamless bus ticket booking through its website and mobile apps covering all major routes.
                  </p>
                    
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="footer-col" style={{ cursor: "pointer" }}>
                <div className="ul title-footer mb-4">
                  <h5 className="text-capitalize fw-bold pb-2 mb-0">information</h5>
                </div>
                <ul className="footer-ul list-unstyled text-capitalize">
                  <li className="footerli mb-2" >
                    <a href="/about-us" style={{ textDecoration: "none" }}>about </a>
                  </li>
                  <li className="footerli mb-2" onClick={() => history.push('/blog-details')}>
                    <a >Blog</a>
                  </li>
                  <li className="footerli mb-2">
                    <a href="/contact-us" style={{ textDecoration: "none" }}>contact</a>
                  </li>
                  {localStorage.getItem('UserID') ? <li className="footerli mb-2" onClick={() => history.push('/profile')}>
                    <a >Account</a>
                  </li> : <li className="footerli mb-2" onClick={() => history.push('/login')}>
                    <a >Login</a>
                  </li>}
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="footer-col">
                <div className="ul title-footer mb-4">
                  <h5 className="text-capitalize fw-bold pb-2 mb-0">Policies</h5>
                </div>
                <ul className="footer-ul list-unstyled text-capitalize">
                  <li className="footerli mb-2">
                    <NavLink to="/profile/terms-conditions" className="smooth mb-2 mt-0 btn-theme-opacity">
                      <span activeClassName="active">Terms & Conditions</span>
                    </NavLink>
                  </li>
                  <li className="footerli mb-2" >
                    <NavLink to="/profile/privacy-policy" className="smooth mb-2 mt-0 btn-theme-opacity">
                      <span activeClassName="active">Privacy Policy</span>
                    </NavLink>
                  </li>
                  <li className="footerli mb-2" >
                    <NavLink to="/profile/shipping-delivery" className="smooth mb-2 mt-0 btn-theme-opacity">
                      <span activeClassName="active">Shipping & Delivery</span>
                    </NavLink>
                  </li>
                  <li className="footerli mb-2" >
                    <NavLink to="/profile/support" className="smooth mb-2 mt-0 btn-theme-opacity">
                      <span activeClassName="active">Support</span>
                    </NavLink>
                  </li>
                  <li className="footerli mb-2" >
                    <NavLink to="/profile/cancel-refund-policy" className="smooth mb-2 mt-0 btn-theme-opacity">
                      <span activeClassName="active">Cancel & Refund</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-12 mb-4 mb-lg-0">
              <div className="footer-col">
                <div className="ul title-footer mb-4">
                  <h5 className="text-capitalize fw-bold pb-2 mb-0">Talk to Us Today</h5>
                </div>
                <ul className="footer-ul list-unstyled text-capitalize">
                  <li className="footerli mb-2 d-flex justify-content-between" >
                    <div className="relative w-full mb-3">
                      <TextField size='small' label='Email ID' />
                    </div>
                    <Button variant='contained' style={{ backgroundColor: "rgb(121 44 143)",height:"100%" }}>Submit</Button>
                  </li>
                  <div >
                    <p > <a  target="_blank"
                     style={{
                      textDecoration: 'none', // Remove underline
                      color: 'inherit',       // Inherit color from parent element
                      fontWeight: 'normal',   // Normal font weight
                      cursor: 'pointer',  
                            // Change cursor to default
                    }} href="https://webvisioninfotech.com/">Copyright © 2024 Sarthi Bus Developed By Webvision Infotech</a>
                    </p>
                  </div>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
